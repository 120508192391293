<template>
  <div class="page-heatmap">
    <div class="row top-row">
      <div
        class="mb-3 col-lg-4 col-xl-3 tree-filters d-flex align-items-center"
      >
        <input type="text" class="input-lg" id="analyticsrange" />
      </div>
      <div
        class="mb-3 col-lg-8 col-xl-9 tree-filters d-flex align-items-center"
      >
        <div v-if="activeTab === 'heatmap'" class="col-auto pe-3 flex-grow-0">
          <select v-model="heatmapFilter" class="input-lg w-100" name="" id="">
            <option value="mean">{{ $t("message.average") }}</option>
            <!--            <option value="median">Mediaan</option>-->
            <option value="max">Max</option>
            <option value="min">Min</option>
            <option value="sd">{{ $t("message.standard_deviation") }}</option>
            <option value="pct">{{ $t('message.percentiles') }}</option>
          </select>
        </div>
        <div v-if="activeTab === 'heatmap'" class="col-auto pe-3 flex-grow-0">
          <Search size="lg" :always-open="true" placeholder="Search team" @search="handleHeatmapSearch" />
        </div>
        <div v-if="activeTab === 'ria'" class="col-auto pe-0 flex-grow-0">
          <search
            :size="'lg'"
            :always-open="true"
            @search="handleRiaSearch"
          ></search>
        </div>
        <div v-if="activeTab === 'heatmap' && !['max', 'min', 'sd'].includes(heatmapFilter)" class="col-auto pe-3 flex-grow-0">
          <select class="input-lg" name="" id="" v-model="heatmapSortField">
            <option value="">Sort by</option>
            <option v-for="(key, i) in keys" :key="key" :value="`${key}`">{{ $t(`message.${headingKeys[i]}`) }}</option>
          </select>
        </div>
        <div v-if="activeTab === 'heatmap' && !['max', 'min', 'sd'].includes(heatmapFilter)" class="col-auto pe-3 flex-grow-0">
          <select class="input-lg" name="" id="" v-model="heatmapSortDirection">
            <option value="asc">Low - High</option>
            <option value="desc">High - Low</option>
          </select>
        </div>
<!--        <div class="d-none ms-auto col-auto pe-3">
          <search size="lg"></search>
        </div>
        <div class="d-none ml-auto col-auto">
          <input type="date" class="input-lg" />
        </div>-->
      </div>
    </div>
    <div class="row analytics-main-content">
      <div class="mb-3 col-lg-4 col-xl-3">
        <div class="card card-rounded overflow-hidden">
          <div class="card-header bgc-koraalrood text-white">
            <div class="overline text-sm">
              <button
                style=""
                class="no-focus d-inline d-lg-none me-3 p-0 navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#analyticsnavbar"
                aria-controls="analyticsnavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="">
                  <i class="text-white fas fa-bars"></i>
                </span>
              </button>
              <span>{{ $t("message.selectYourAnalysis") }}</span>
            </div>
          </div>
          <div class="card-body p-0 p-lg-3">
            <nav class="p-0 analysis-nav navbar navbar-expand-lg">
              <div class="collapse navbar-collapse" id="analyticsnavbar">
                <ul
                  class="p-3 navbar-nav nav nav-pills flex-column"
                  id="pills-tab"
                  role="tablist"
                >
                <li
                    v-if="settings.stats_descriptives"
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                      :class="activeTab === 'descriptives' ? 'active' : ''"
                      @click="activeTab = 'descriptives'"
                      type="button"
                      role="tab"
                      aria-controls="pills-descriptives"
                      aria-selected="false"
                    >
                      <i
                        class="c-koraalrood c-200 fa-fw fas fa-list fa-lg me-2"
                      ></i
                      ><span class="text-sentence">{{ $t("message.descriptives") }}</span>
                    </button>
                  </li>
                  <li
                    v-if="settings.stats_histograms"
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                      :class="activeTab === 'histogram' ? 'active' : ''"
                      @click="activeTab = 'histogram'"
                      type="button"
                      role="tab"
                      aria-controls="pills-histogram"
                      aria-selected="false"
                    >
                      <i
                        class="
                          c-koraalrood c-200
                          fa-fw
                          fas
                          fa-chart-bar fa-lg
                          me-2
                        "
                      ></i
                      ><span class="text-sentence">{{ $t("message.histogram") }}</span>
                    </button>
                  </li>
                  <li
                    v-if="settings.stats_heatmap"
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                      :class="activeTab === 'heatmap' ? 'active' : ''"
                      @click="activeTab = 'heatmap'"
                      type="button"
                      role="tab"
                      aria-controls="pills-heatmap"
                      aria-selected="true"
                    >
                      <i
                        class="c-koraalrood c-200 fa-fw fas fa-th fa-lg me-2"
                      ></i
                      ><span class="text-sentence">{{ $t("message.heatmap") }}</span>
                    </button>
                  </li>
                  <li
                    v-if="settings.stats_ria"
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                      :class="activeTab === 'ria' ? 'active' : ''"
                      @click="activeTab = 'ria'"
                      type="button"
                      role="tab"
                      aria-controls="pills-ria"
                      aria-selected="false"
                    >
                      <i
                        class="
                          c-koraalrood c-200
                          fa-fw
                          fas
                          fa-chart-bar fa-lg
                          me-2
                        "
                      ></i
                      ><span class="text-sentence">{{ $t("message.ria") }}</span>
                    </button>
                  </li>
                  <li
                    v-if="settings.stats_network"
                    class="nav-item"
                    role="presentation"
                  >
                    <button
                      class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                      :class="activeTab === 'network' ? 'active' : ''"
                      @click="activeTab = 'network'"
                      type="button"
                      role="tab"
                      aria-controls="pills-network"
                      aria-selected="false"
                    >
                      <i
                        class="
                          c-koraalrood c-200
                          fa-fw
                          fas
                          fa-chart-network fa-lg
                          me-2
                        "
                      ></i
                      ><span class="text-sentence">{{ $t("message.network") }}</span>
                    </button>
                  </li>
                  <li
                      class="nav-item"
                      role="presentation"
                  >
                    <button
                        class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                        :class="activeTab === 'participation' ? 'active' : ''"
                        @click="activeTab = 'participation'"
                        type="button"
                        role="tab"
                        aria-controls="pills-participation"
                        aria-selected="false"
                    >
                      <i
                          class="c-koraalrood c-200 fa-fw fas fa-percent fa-lg me-2"
                      ></i
                      ><span class="text-sentence">{{ $t("message.participation") }}</span>
                    </button>
                  </li>
                  <li
                      class="nav-item"
                      role="presentation"
                  >
                    <button
                        class="
                        w-100
                        text-start text-capitalize
                        pill-koraalrood
                        nav-link
                      "
                        :class="activeTab === 'definitions' ? 'active' : ''"
                        @click="activeTab = 'definitions'"
                        type="button"
                        role="tab"
                        aria-controls="pills-definitions"
                        aria-selected="false"
                    >
                      <i
                          class="c-koraalrood c-200 fa-fw fas fa-books fa-lg me-2"
                      ></i
                      ><span class="text-sentence">{{ $t("message.definitions") }}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="mb-3 col-lg-8 col-xl-9">
        <!--{{activeTab}}-->
        <div class="h-100 tab-content" id="pills-tabContent">
          <div
            v-if="activeTab === 'heatmap'"
            class="h-100"
            id="pills-heatmap"
            role="tabpanel"
            aria-labelledby="pills-heatmap-tab"
          >
            <div class="h-100 heatmap-row">
<!--              <div
                class="h-100 card card-rounded"
                style="max-width: 100vw; overflow-x: auto"
              >-->
                <heatmap-component
                    ref="heatmap"
                  :minDate="isoMinDate"
                  :maxDate="isoMaxDate"
                  :filter="heatmapFilter"
                    :sort-by="heatmapSortField"
                    :sort-direction="heatmapSortDirection"
                ></heatmap-component>
<!--              </div>-->
            </div>
          </div>
          <div
            v-if="activeTab === 'network'"
            class="h-100"
            id="pills-network"
            role="tabpanel"
            aria-labelledby="pills-network-tab"
          >
            <div class="h-100 network-row">
              <div
                class="card card-rounded overflow-hidden"
                style="max-width: 100vw"
              >
                <div class="card-header bgc-koraalrood text-white">
                  <span class="text-sentence">{{ $t("message.network") }}</span>
                </div>
                <div class="p-0 card-body">
                  <network-map
                    :minDate="isoMinDate"
                    :maxDate="isoMaxDate"
                    :visible="activeTab"
                    class="h-100"
                  ></network-map>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activeTab === 'histogram'"
            class="h-100"
            id="pills-histogram"
            role="tabpanel"
            aria-labelledby="pills-histogram-tab"
          >
            <div class="h-100 histogram">
              <div
                class="h-100 card card-rounded overflow-hidden"
                style="max-width: 100vw"
              >
                <div class="card-header bgc-koraalrood text-white">
                  <span class="text-sentence">{{ $t("message.histogram") }}</span>
                </div>
                <div class="card-body">
                  <histograms
                    :minDate="isoMinDate"
                    :maxDate="isoMaxDate"
                  ></histograms>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="activeTab === 'descriptives'"
            class="h-100"
            id="pills-descriptives"
            role="tabpanel"
            aria-labelledby="pills-descriptives-tab"
          >
            <div class="h-100 heatmap-row">
              <div
                class="h-100 card card-rounded overflow-hidden"
                style="max-width: 100vw"
              >
                <descriptives
                  :minDate="isoMinDate"
                  :maxDate="isoMaxDate"
                ></descriptives>
              </div>
            </div>

            <!--            <div class="h-100 network-row ">-->
            <!--              <div class="h-100 card card-rounded overflow-hidden" style="max-width: 100vw">-->
            <!--                <div class="card-header bgc-koraalrood text-white">Descriptives</div>-->
            <!--                <div class="card-body">-->
            <!--                  -->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div
            v-if="activeTab === 'ria'"
            class="h-100"
            id="pills-ria"
            role="tabpanel"
            aria-labelledby="pills-ria-tab"
          >
            <div class="h-100 heatmap-row">
              <div
                class="h-100 card card-rounded overflow-hidden"
                style="max-width: 100vw"
              >
                <ria
                  ref="ria"
                  :minDate="isoMinDate"
                  :maxDate="isoMaxDate"
                ></ria>
              </div>
            </div>
          </div>
          <div
              v-if="activeTab === 'participation'"
              class="h-100"
              id="pills-participation"
              role="tabpanel"
              aria-labelledby="pills-participation-tab"
          >
            <div class="h-100 heatmap-row">
                <Participation />
            </div>
          </div>
          <div
              v-if="activeTab === 'definitions'"
              class="h-100"
              id="pills-definitions"
              role="tabpanel"
              aria-labelledby="pills-definitions-tab"
          >
            <div class="h-100 heatmap-row">
<!--              <Participation />-->
              <Definitions />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from "@/views/components/simple/Search";
import NetworkMap from "@/views/components/charts/NetworkMap";
import HeatmapComponent from "@/views/components/analytics/Heatmap";
import Histograms from "@/views/components/analytics/Histograms";
import Descriptives from "@/views/components/analytics/Descriptives";
import Ria from "@/views/components/analytics/Ria";
import moment from "moment";
import daterangepicker from "daterangepicker";
import jquery from "jquery";
import Participation from "@/views/components/analytics/Participation";
import Definitions from "@/views/components/analytics/Definitions";
const $ = jquery;

export default {
  mounted() {
    this.initDaterange();
    let tab = "";
    if (this.settings) {
      if (this.settings.stats_descriptives) {
        tab = "descriptives";
      } else if (this.settings.stats_heatmap) {
        tab = "heatmap";
      } else if (this.settings.stats_network) {
        tab = "network";
      } else if (this.settings.stats_histograms) {
        tab = "histogram";
      } else if (this.settings.stats_ria) {
        tab = "ria";
      }
    }
    this.activeTab = tab;
    this.mitt.emit("loadcomplete", { complete: true });
  },
  computed: {
    maxDate() {
      return moment(this.endDate).format("YYYY-MM-DD");
    },
    isoMinDate() {
      return moment(this.startDate).toISOString();
    },
    isoMaxDate() {
      return moment(this.maxDate).toISOString();
    },
    settings() {
      return this.$store.getters.getPublicSettings;
    },
  },
  methods: {
    initDaterange() {
      let _this = this;
      setTimeout(function () {
        const el = document.getElementById("analyticsrange");
        const drp = new daterangepicker(el, {
          locale: {
            format: "DD/MM/YYYY",
          },
        });
        $("#analyticsrange").on("apply.daterangepicker", function () {
          _this.startDate = drp.startDate.format("YYYY-MM-DD");
          _this.endDate = drp.endDate.format("YYYY-MM-DD");
        });
      }, 1000);
    },
    handleRiaSearch(val) {
      this.$refs.ria.handleSearch(val);
    },

    handleHeatmapSearch(val) {
      this.$refs.heatmap.handleSearch(val);
    }
  },
  components: {
    Definitions,
    Participation,
    Histograms,
    NetworkMap,
    Search,
    HeatmapComponent,
    Descriptives,
    Ria,
  },
  watch: {
    heatmapFilter(val) {
      if (['max', 'min', 'sd'].includes(val)) {
        this.heatmapSortField = "";
      }
    }
  },
  data() {
    return {
      activeTab: "",
      heatmapFilter: "mean",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      heatmapSortDirection: 'asc',
      heatmapSortField: "",
      headingKeys: ["WB", "ENG", "EB", "UIT", "HIN", "PHB", "MOT", "AT", "PR", "ONG"],
      keys: ["WB", "Eng", "EB", "UIT", "HIN", "PHB", "IM", "ATT", "PRES", "ONG"],
    };
  },
};
</script>

<style lang="scss" scoped>
.analytics-main-content {
  // min-height: 70vh;
}
</style>