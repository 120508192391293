<template>
  <div v-if="loading" class="position-relative p-4">
    <div class="container-spinner text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">{{ $t("message.loading") }}...</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="card rounded-8 overflow-hidden bg-white mb-4" v-for="vitamin in vitamins" :key="vitamin.id">
      <header class="card-header overline" :class="'bgc-' + vitamin.color">
        <span class="text-sm">{{ vitamin.title }}</span>
      </header>
      <main class="card-body bg-white">
        <p>{{ vitamin.body }}</p>
        <div class="row">
          <div class="col-md-6 my-3" v-for="factor in vitamin.factors" :key="factor.id">
            <div class="card overflow-hidden rounded" :class="factor.isOpen ? 'h-100' : 'h-auto'">
              <div class="card-header d-flex justify-content-between items-center overline c-50" :class="'bgc-' + vitamin.color" style="cursor: pointer" @click="factor.isOpen = !factor.isOpen">
                <span class="text-sm">{{ factor.title }}</span>
                <span class="d-inline-block chevron"  :class="factor.isOpen && 'chevron-open'"
                ><i class="fas fa-chevron-right"></i
                ></span>
              </div>
              <div class="card-body" v-if="factor.isOpen">
                <p>{{ factor.body }}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "Definitions",
  data() {
    return {
      vitamins: [],
      loading: true,
    }
  },
  mounted() {
    this.getData();
    this.mitt.on('langChanged', () => {
      this.getData();
    })
  },
  methods: {
    async getData() {
      this.loading = true;
      const { data: vitamins } = await this.axios.get('/vitamins?_locale=' + (this.$i18n ? this.$i18n.locale : 'en' ));
      this.vitamins = vitamins;

      for (const vitamin of vitamins) {
        for (const factor of vitamin.factors) {
          factor.isOpen = false;
        }
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.chevron {
  transition: all 0.2s ease;
}

.chevron-open {
  transform-origin: center;
  transform: rotateZ(90deg);
}
</style>