<template>
  <div class="row">
    <template v-if="loaded">
      <div class="col-lg-6" v-for="entry in checkups" :key="entry.entry.id">
        <div class="card mb-4 h-full">
          <div
              class="card-header c-50 pe-2"
              :class="'bgc-' + entry.entry.checkup.color"
          >
            <div class="overline text-sm">{{ entry.entry.checkup.title }}</div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center pb-2" style="cursor: pointer" @click="entry.isOpen = !entry.isOpen">
              <div class="d-flex w-100 align-items-center">
                <progress-chart
                    style="height: 48px; width: 48px"
                    :pct="(entry.finishedCount / entry.users) * 100"
                    :border-width="3"
                    :f-size="'sm'"
                    :color="entry.entry.checkup.color"
                />
                <div class="ms-2">
                  <p class="mb-0">{{ $t('message.participation') }}</p>
                  <span><img
                      class="me-1"
                      style="height: 14px"
                      src="../../../assets/icons/Icon-sidebar-Users.svg"
                      alt=""
                  />{{ entry.users }} <img
                      src="../../../assets/icons/Icon-sidebar-tree.svg"
                      style="height: 14px"
                      alt=""
                      class="me-1 ms-2"
                  />{{ entry.teamCount }} <i
                      style="height: 14px"
                      alt=""
                      class="me-1 ms-2 fas fa-flag-checkered"
                  />{{ entry.finishedCount }}
                  <i
                      style="height: 14px"
                      alt=""
                      class="me-1 ms-2 fas fa-walking c-koraalrood"
                  />{{ entry.unfinishedCount }}</span>
                </div>
                <div class="flex-grow-1 text-end">
                  <span class="d-inline-block chevron"  :class="entry.isOpen && 'chevron-open'"
                  ><i class="fas fa-chevron-right"></i
                  ></span>
                </div>
              </div>
            </div>
            <div v-if="entry.isOpen">
                <div v-for="child in entry.children" :key="child.title" class="ms-3 ps-4 border-start py-4" >
                  <div class="d-flex justify-content-between align-items-center " @click="child.isOpen = !child.isOpen" style="cursor: pointer">
                    <div class="d-flex align-items-center">
                      <progress-chart
                          style="height: 38px; width: 38px"
                          :pct="(child.participation / child.users) * 100"
                          :border-width="2"
                          :f-size="'sm'"
                          :color="entry.entry.checkup.color"
                      />
                      <div class="ms-2">
                        <p class="mb-0">{{ child.title }}</p>
                        <span><img
                            class="me-1"
                            style="height: 14px"
                            src="../../../assets/icons/Icon-sidebar-Users.svg"
                            alt=""
                        />{{ child.users }} <img
                            src="../../../assets/icons/Icon-sidebar-tree.svg"
                            style="height: 14px"
                            alt=""
                            class="me-1 ms-2"
                        />{{ child.children.length }}</span>
                      </div>
                    </div>
                    <div class="flex-grow-1 text-end">
                    <span class="d-inline-block chevron"  :class="child.isOpen && 'chevron-open'"
                    ><i class="fas fa-chevron-right"></i
                    ></span>
                    </div>
                  </div>
                  <div v-if="child.isOpen">
                    <Paginated :content="child.children" :total-rows="child.children.length" :per-page="10" @change="(content) => setPaginatedChildren(content, child)">
                      <div v-for="item in child.paginated" :key="item.title" class="d-flex ms-3 ps-4 border-start justify-content-between align-items-center">
                        <!-- TODO: Paginate these for checkups with many teams -->
                        <div class="w-100 d-flex align-items-center divide">
                          <progress-chart
                              style="height: 38px; width: 38px"
                              class="col-auto"
                              :pct="(item.participation / item.users) * 100"
                              :border-width="2"
                              :f-size="'sm'"
                              :color="entry.entry.checkup.color"
                          />
                          <div class="col w-100 d-flex flex-wrap justify-content-between ms-2">
                            <p class="mb-0 flex-shrink-1 text-wrap">{{ item.title }}</p>
                            <span class="d-block flex-grow-1 text-end"><img
                                src="../../../assets/icons/Icon-sidebar-Users.svg"
                                style="height: 14px"
                                alt=""
                                class="me-1 ms-2"
                            />{{ item.users }}</span>
                          </div>
                        </div>
                      </div>
                    </Paginated>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="position-relative p-4">
      <div class="container-spinner text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import ProgressChart from "@/views/components/ProgressChart";
import Paginated from "@/views/components/simple/Paginated";
export default {
  name: "Participation",
  components: { Paginated, ProgressChart },
  data() {
    return {
      loaded: false,
      checkups: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    setPaginatedChildren(content, entry) {
      entry.paginated = content;
    },
    async getData() {
      console.time('Fetching data')
      const { data: participation } = await this.axios.get('/swarmcomputeds/participation');
      /*console.group('Fetching data for participation')
      console.time('Load')
      const { data: computeds } = await this.axios.get('/swarmcomputeds?checkupentry_gte=0&_limit=-1');
      console.log('Fetched computeds')
      const grouped = _.groupBy(computeds, 'checkupentry');
      console.log('Grouped computeds')
      console.timeLog('Load')
      let newGroup = []
      let teamCount = 0;
      for (const [i, group] of Object.entries(grouped)) {
        const { data: entry } = await this.axios.get(`/checkupentries?id=${i}`);
        const {data: count } = await this.axios.get(`/checkupsubmissions/count?checkupentry=${i}&isfinished=true`);
        const { data: totalCount } = await this.axios.get(`/checkupsubmissions/count?checkupentry=${i}`);

        let groupedChildren = {};
        for (const item of entry[0].checkup.swarms) {
          const { hasbases } = item;
          for (const base of hasbases) {
            if (!groupedChildren[base.id]) {
              groupedChildren[base.id] = {
                users: 0,
                participation: 0,
                title: base.title,
                isOpen: false,
                children: []
              }
            }

            if (item.zerglingcount > 0) {
              groupedChildren[base.id].users += item.zerglingcount;
              const computed = group.find(comp => comp.swarm === item.id)
              if (computed) {
                groupedChildren[base.id].participation += computed.users.participation;
              }
              groupedChildren[base.id].children.push({
                users: item.zerglingcount,
                participation: computed ? computed.users.participation : 0,
                title: item.title,
              });
              teamCount++;
            }
          }
        }
        newGroup.push({
          entry: entry[0],
          isOpen: true,
          users: entry[0].checkup.zerglingcount,
          participation: Math.round(count / entry[0].checkup.zerglingcount * 100),
          teamCount: teamCount,
          finishedCount: count,
          unfinishedCount: totalCount - count,
          children: groupedChildren,
        })
      }
      console.log('Sorted and grouped computed')
      console.timeEnd('Load')
      console.groupEnd()
      // console.log(newGroup);*/
      this.checkups = participation;
      this.loaded = true;
      console.timeEnd('Fetching data')
    }
  }
}
</script>

<style scoped>
.chevron {
  transition: all 0.2s ease;
}

.divide:not(:last-child):not(:first-child) {
  padding: 1rem 0;
}

.divide:not(:last-child) {
  border-bottom: solid 1px #ebebeb;
}

.divide:first-child {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.divide:last-child {
  padding-top: 1rem;
}

.chevron-open {
  transform-origin: center;
  transform: rotateZ(90deg);
}
</style>