<template>
   <Paginated
        :request="request"
        @change="handlePagination"
        :total-rows="totalCount"
        :per-page="20"
      >
        <div class="h-100 card card-rounded" style="max-width: 100vw; overflow-x: auto">
        <table class="table table-bordered w-auto mb-0">
          <thead>
            <tr>
              <th class="bgc-koraalrood"></th>
              <th v-for="(key, i) in headingKeys" :key="i" class="bgc-koraalrood c-200 text-sentence d-table-cell">{{ $t(`message.${key.toUpperCase()}`) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="computed in computeds" :key="computed.id">
              <td class="team-info">
                <div class="tname text-sentence">{{ computed.swarm.title }}</div>
                <div class="info-icons d-flex">
                  <div class="data-point px-1">
                    <img
                        src="@/assets/icons/Icon-sidebar-Users.svg"
                        alt=""
                    />&nbsp;{{ computed.swarm.zerglingcount }}
                  </div>
                  <div class="data-point px-1">
                    <img
                        src="@/assets/icons/Icon-tree-manager-profile.svg"
                        alt=""
                    />&nbsp;{{ computed.swarm.overlordcount }}
                  </div>
                </div>
              </td>
              <td colspan="10" v-if="computed.swarm.zerglingcount < 3" class="bg-light text-disabled text-center">
                {{ $t('message.analytics_team_too_small') }}
              </td>
              <template v-else>
              <td v-for="(key, i) in keys" :key="computed.id + i">
                <progress-chart
                    style="height: 64px; width: 64px"
                    class="mx-auto"
                    :pct="filter === 'pct' ? getNormering(percentileTags[i], computed.stats.data[`${key}_mean`]) : (computed.stats.data[`${key}_${filter}`] / 7) * 100"
                    :border-width="4"
                    :f-size="'sm'"
                    :type="filter"
                    :analytics="key"
                    :invert-colors="['HIN', 'ONG'].includes(key)"
                />
              </td>
              </template>
            </tr>
          </tbody>
        </table>
        </div>
      </Paginated>
</template>
<script>
import ProgressChart from "@/views/components/ProgressChart";
import moment from "moment";
import Paginated from "@/views/components/simple/Paginated";

export default {
  components: {Paginated, ProgressChart },
  props: {
    maxDate: {},
    minDate: {
      default() {
        return null;
      },
    },
    filter: {
      default() {
        return "mean";
      },
      type: String,
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'asc'
    }
  },
  methods: {
    handlePagination(content) {
      this.computeds = content
      this.isRetrieved = true;
    },
    handleSearch(search) {
      this.search = search;
      this.getHeatmap();
    },
    async getHeatmap() {
      this.isRetrieved = false;
      let timeFilter = "created_at_lte=" + this.maxDate;
      if (this.minDate) {
        timeFilter += "&created_at_gte" + this.minDate;
      }
      if (
        moment(this.maxDate).isSame(moment(), "day") &&
        (!this.minDate || moment(this.minDate).isSame(moment(), "day"))
      ) {
        timeFilter = "isactive=true";
      }

      let sort = '';
      if (this.sortBy) {
        if (this.filter === 'pct') {
          const tagIndex = this.keys.findIndex(item => item === this.sortBy);
          sort = `&_sort=${this.sortBy}|${this.percentileTags[tagIndex]}_${this.filter}:${this.sortDirection}`
        } else {
          sort = `&_sort=${this.sortBy}_${this.filter}:${this.sortDirection}`
        }
      }

      let search = '';
      if (this.search) {
        search = `&title_contains=${this.search.toLowerCase()}`
      }

      const { data: total } = await this.axios.get("/swarmcomputeds/heatmap/count?" + timeFilter + search);
      this.totalCount = total;
      this.request = "/swarmcomputeds/heatmap?" + timeFilter + sort + search;
    },
    async getNormeringData() {
      const get = await this.axios.get(
          "/analytics-items?isactive=true&type=normeringTeams&_sort=subtype:asc"
      );
      this.normeringData = get.data;
    },
    getNormering(type, val) {
      const n = this.normeringData.find((norm) => {
        return norm.subtype.toLowerCase() === type.toLowerCase();
      });
      // console.log(val, n.data[val], n.data);
      if (n && n.data[val]) {
        return n.data[val];
      } else {
        return 0;
      }
    },
  },
  data() {
    return {
      isRetrieved: false,
      computeds: [],
      request: '/swarmcomputeds/heatmap?isactive=true',
      totalCount: 0,
      search: '',
      normeringData: [],
      headingKeys: ["WB", "ENG", "EB", "UIT", "HIN", "PHB", "MOT", "AT", "PR", "ONG"],
      keys: ["WB", "Eng", "EB", "UIT", "HIN", "PHB", "IM", "ATT", "PRES", "ONG"],
      percentileTags: ['wellbeing', 'Engagement', 'Energiebronnen', 'Uitdagingen', 'Hinderpalen', 'Hulpbronnen', 'Motivatie', 'Attitudes', 'Prestaties', 'Ongezondheid'],
    };
  },
  watch: {
    minDate(oldval, newval) {
      if (oldval !== newval) {
        this.getHeatmap();
      }
    },
    maxDate(oldval, newval) {
      if (oldval !== newval) {
        this.getHeatmap();
      }
    },
    filter(oldval, newval) {
      if (oldval !== newval && this.sortBy) {
        console.log(oldval, newval, this.sortBy)
        this.getHeatmap();
      }
    },
    sortBy(oldval, newval) {
      if (oldval !== newval) {
        console.log('sort', oldval, newval)
        this.getHeatmap();
      }
    },
    sortDirection(oldval, newval) {
      if (oldval !== newval) {
        console.log('sort direction', oldval, newval)
        this.getHeatmap();
      }
    }
  },
  mounted() {
    this.getHeatmap();
    this.getNormeringData();
  },
};
</script>

<style lang="scss" scoped>
table {
  th {
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle;
  }

  table-layout: fixed;

  td {
    height: 96px;
  }

  td,
  th {
    overflow: hidden;
    vertical-align: middle;
    width: 96px;
    //min-width: 96px;
    &:first-child {
      vertical-align: bottom;
      width: 200px;
      //min-width: 20%;
    }
  }
}

.team-info {
  padding-left: 20px;
  padding-bottom: 15px;
}

.heatmap-chart {
  height: 64px;
  width: 64px;
}
</style>